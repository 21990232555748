import React, { Component } from 'react';

import '../../App.scss';

class TreeLeft extends Component {

  render() {
    return (

      <div className="TreeLeft">
      </div>
    )
  }
}

export default TreeLeft;
