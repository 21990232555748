const chatbotSteps = [
  {
    id: "1",
    message: `Ho Ho Ho… Happy Holidays!`,
    trigger: "2"
  },
  {
    id: "2",
    message: `I am iSanta, here to help you find the perfect gift for a friend or colleague.`,
    trigger: "3"
  },
  {
    id: "3",
    message: `Press start to begin.`,
    trigger: "4"
  },
  {
    id: "4",
    options: [{ value: 5, label: "Start", trigger: "7" }]
  },
  {
    id: "7",
    message: `So now, imagine you're sat on a virtual Santa's knee and tell me your name.
			(You can use a false name if you really want to)`,
    trigger: "name"
  },
  {
    id: "name",
    user: true,
    trigger: "9"
  },
  {
    id: "9",
    message: `Thank you, {previousValue}. Now tell me who you are getting a gift for.`,
    trigger: "10"
  },
  {
    id: "10",
    user: true,
    trigger: "11"
  },
  {
    id: "11",
    message: `Aren't they lucky to know you! Be honest, has {previousValue} been naughty or nice this year?`,
    trigger: "12"
  },
  {
    id: "12",
    options: [
      { value: "Naughty", label: "Naughty", trigger: "13" },
      { value: "Nice", label: "Nice", trigger: "14" }
    ]
  },
  {
    id: "13",
    message: "Oh dear, been naughty have they!",
    trigger: "15Naughty"
  },
  {
    id: "14",
    message: "Well, that's great!",
    trigger: "15"
  },
  //nice path
  {
    id: "15",
    message: "Tell me, do they have a good sense of humour?",
    trigger: "16"
  },
  {
    id: "16",
    options: [
      {
        value: "Good sense of humour",
        label: "Good sense of humour",
        trigger: "19"
      },
      {
        value: "No sense of humour",
        label: "No sense of humour",
        trigger: "20"
      }
    ]
  },
  {
    id: "19",
    message: `Nice and they have a good sense of humour. They must be in store for a real treat!`,
    trigger: "21"
  },
  {
    id: "20",
    message:
      "Nice, but no sense of humour... Finding the right present for them could be tricky.",
    trigger: "21"
  },
  //nice end
  //Naughty path
  {
    id: "15Naughty",
    message: "Tell me, do they have a good sense of humour?",
    trigger: "16Naughty"
  },
  {
    id: "16Naughty",
    options: [
      {
        value: "Good sense of humour",
        label: "Good sense of humour",
        trigger: "17Naughty"
      },
      {
        value: "No sense of humour",
        label: "No sense of humour",
        trigger: "18Naughty"
      }
    ]
  },
  {
    id: "17Naughty",
    message: "Well, that's good to hear.",
    trigger: "21"
  },
  {
    id: "18Naughty",
    message: "Oh no... naughty and not much fun.",
    trigger: "21"
  },
  //Naughty path end
  {
    id: "21",
    message:
      "Last question now: are they a creative person, or maybe more of an organised person?",
    trigger: "22"
  },
  {
    id: "22",
    options: [
      { value: "Creative", label: "Creative", trigger: "23" },
      { value: "Organised", label: "Organised", trigger: "23" }
    ]
  },
  {
    id: "23",
    message: "Okay...",
    trigger: "24"
  },
  {
    id: "24",
    message: "Calculating present now...",
    trigger: "25"
  },
  {
    id: "25",
    message: "Verifying gift...",
    trigger: "26"
  },
  {
    id: "26",
    message: "I have the perfect thing for them!",
    trigger: "27"
  },
  {
    id: "27",
    options: [{ value: "Press to view", label: "Press to view", trigger: "" }],
    end: true
  }
];

export default chatbotSteps;
