import React, { Component } from "react";

import Chat from "./components/Chat/Chat";
import Logo from "./components/Logo/Logo";
import TreeLeft from "./components/TreeLeft/TreeLeft";
import TreeRight from "./components/TreeRight/TreeRight";
import Moon from "./components/Moon/Moon";
import BushRight from "./components/BushRight/BushRight";
import BushLeft from "./components/BushLeft/BushLeft";
import Present from "./components/Present/Present";
import Snowflakes from "./components/Snowflakes/Snowflakes";
import PrivacyLink from "./components/PrivacyLink/PrivacyLink";

import snowflakeSettings from "./components/Snowflakes/snowflakeSettings";

import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      button: {
        showButton: false,
        isLastScreen: true,
        buttonData: {
          lastScreen: " Try Again",
          presentScreen: "Done"
        }
      },
      chatData: {},
      chatComplete: false
    };

    // Bind functions
    this.givePresents = this.givePresents.bind(this);
  }

  // Handle chat data
  givePresents = chatData => {
    console.log(chatData);
    this.setState({
      chatData: chatData,
      chatComplete: true
    });
  };

  moveToFinalScreen = () => {
    console.log("App complete");
    this.setState({
      presentGiven: true
    });
  };

  render() {
    return (
      <div className="App">
        <div
          className={
            "pageBody-overlay" + (this.state.chatComplete ? " isShown" : "")
          }
        />
        <Chat givePresents={this.givePresents} />
        <Logo />
        <Moon />
        <TreeLeft />
        <TreeRight />
        <Snowflakes params={snowflakeSettings} />
        <BushLeft />
        <BushRight />
        <Present
          chatComplete={this.state.chatComplete}
          chatData={this.state.chatData}
          handleFinalClick={this.moveToFinalScreen}
        />
        <PrivacyLink href="https://thisplace.com/privacy-policy/">
          Privacy policy
        </PrivacyLink>
      </div>
    );
  }
}

export default App;
