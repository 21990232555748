// Breakpoint for CSS in JS
const breakpoints = {
  screenXs: "0px",
  screenSm: "592px",
  screenMd: "784px",
  screenLg: "1200px",
  screenLgMax: "1440px"
};

export default breakpoints;
