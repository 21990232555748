import React, { Component } from "react";

import "./Present.scss";

import Santa from "../../assets/images/RobotAvatar.png";
import finalImage from "../../assets/images/XmasTree.png";

import presentData from "./presentData";

class Present extends Component {
  constructor(props) {
    super(props);

    this.state = {
      button: {
        showButton: true,
        isLastScreen: false,
        buttonData: {
          lastScreen: " Try Again",
          presentScreen: "Done"
        }
      },
      appComplete: false
    };

    this.moveToFinalScreen = this.moveToFinalScreen.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.chatData !== prevProps.chatData) {
      if (this.props.chatData.ethics === "Naughty") {
        if (this.props.chatData.humor === "Good sense of humour") {
          if (this.props.chatData.type === "Creative") {
            this.setState({
              presentTitle: presentData.DishGloves.title,
              presentImageUrl: presentData.DishGloves.imageUrl,
              presentDescription: presentData.DishGloves.presentDescription
            });
          } else if (this.props.chatData.type === "Organised") {
            this.setState({
              presentTitle: presentData.Cactus.title,
              presentImageUrl: presentData.Cactus.imageUrl,
              presentDescription: presentData.Cactus.presentDescription
            });
          }
        } else if (this.props.chatData.humor === "No sense of humour") {
          if (this.props.chatData.type === "Creative") {
            this.setState({
              presentTitle: presentData.Catapult.title,
              presentImageUrl: presentData.Catapult.imageUrl,
              presentDescription: presentData.Catapult.presentDescription
            });
          } else if (this.props.chatData.type === "Organised") {
            this.setState({
              presentTitle: presentData.Clips.title,
              presentImageUrl: presentData.Clips.imageUrl,
              presentDescription: presentData.Clips.presentDescription
            });
          }
        }
      } else if (this.props.chatData.ethics === "Nice") {
        if (this.props.chatData.humor === "Good sense of humour") {
          if (this.props.chatData.type === "Creative") {
            this.setState({
              presentTitle: presentData.Unicorn.title,
              presentImageUrl: presentData.Unicorn.imageUrl,
              presentDescription: presentData.Unicorn.presentDescription
            });
          } else if (this.props.chatData.type === "Organised") {
            this.setState({
              presentTitle: presentData.Umbrella.title,
              presentImageUrl: presentData.Umbrella.imageUrl,
              presentDescription: presentData.Umbrella.presentDescription
            });
          }
        } else if (this.props.chatData.humor === "No sense of humour") {
          if (this.props.chatData.type === "Creative") {
            this.setState({
              presentTitle: presentData.BrainHat.title,
              presentImageUrl: presentData.BrainHat.imageUrl,
              presentDescription: presentData.BrainHat.presentDescription
            });
          } else if (this.props.chatData.type === "Organised") {
            this.setState({
              presentTitle: presentData.Vacuum.title,
              presentImageUrl: presentData.Vacuum.imageUrl,
              presentDescription: presentData.Vacuum.presentDescription
            });
          }
        }
      }
    }
  }

  moveToFinalScreen() {
    this.setState({
      appComplete: true
    });
  }

  reloadApp() {
    window.location.reload();
  }

  render() {
    if (!this.state.appComplete) {
      return (
        <div
          className={"Present" + (this.props.chatComplete ? " isShown" : "")}
        >
          <div className="Present__wrapper">
            <img className="wrapper__santa-icon" src={Santa} alt="" />
            <img
              className="wrapper__present-image"
              src={this.state.presentImageUrl}
              alt=""
            />
            <div className="wrapper__text">
              <p className="text__present-title">
                {this.props.chatData.receiver} will love {" "}
                {this.state.presentTitle}
              </p>
              <p className="text__description">
                {this.state.presentDescription}
              </p>
            </div>
          </div>
          <p className="Present__wrong">
            {" "}
            Not quite right? <span onClick={this.reloadApp}>Try again</span>
          </p>

          {/* Duplicated markup from Button component to add onClick event*/}
          <div className="Button">
            <button onClick={this.moveToFinalScreen}>Done</button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={"Present" + (this.props.chatComplete ? " isShown" : "")}
        >
          <div className="Present__wrapper">
            <img className="wrapper__santa-icon" src={Santa} alt="" />
            <img className="wrapper__present-image" src={finalImage} alt="" />
            <div className="wrapper__text">
              <p className="text__present-title">Thank you for using iSanta!</p>
              <p className="text__description">
                Wishing you happy holidays from myself and This Place. Here's to
                an amazing 2019!
              </p>
            </div>
          </div>
          <p className="Present__wrong">
            Not quite right? <span onClick={this.reloadApp}>Try again</span>
          </p>
        </div>
      );
    }
  }
}

export default Present;
