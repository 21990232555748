import styled from "styled-components";
import breakpoints from "./../../globalStyles/breakpoints";

const ChatWrapper = styled.div`
  z-index: 400;

  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  height: auto;

  & .rsc-header {
    display: none;
  }

  & .Chat__container {
    height: auto;

    & .rsc-container {
      background: none;
      box-shadow: none;
      width: 100%;
      min-width: 260px;

      @media (${breakpoints.screenMd}) {
        max-width: 688px;
      }
    }

    & .rsc-ts-bubble {
      background: rgba(24, 0, 42, 0.9);
      border-radius: 8px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid #000000;
      max-width: 100%;

      @media (${breakpoints.screenSm}) {
        max-width: 50%;
      }
    }

    & .rsc-ts-user .rsc-ts-bubble {
      background: rgba(255, 255, 255, 0.9);
    }

    & .rsc-input {
      background: #ffffff;
      border-radius: 40px;
      border: none;
    }

    & .rsc-content {
      margin: 0 12px;
      overflow-y: auto;
      max-height: 50vh;

      @media (min-width: 784px) {
        max-height: 45vh;
        font-size: 21px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & .rsc-footer {
      margin: 180px 10px 0;
      background: white;
      border-radius: 40px;

      @media (min-width: 584px) {
        margin: 350px 24px 0;
      }

      .rsc-input {
        padding: 16px 65px 16px 30px;

        @media (${breakpoints.screenMd}) {
          padding: 31px 65px 31px 30px;
        }

        &::placeholder {
          font-family: "Helvetica Neue", sans-serif;
          font-size: 18px;
          color: #b289c5;

          @media (${breakpoints.screenMd}) {
            font-size: 21px;
          }
        }
      }

      .rsc-submit-button {
        right: 8px;
        top: 0;
        bottom: 0;
      }
    }
  }
`;

export default ChatWrapper;
