const presentData = {
  BrainHat: {
    title: "this Knitted Thinking Cap",
    imageUrl: require("./../../assets/images/Presents/00-BrainHat.jpg"),
    presentDescription:
      "Being a serious creative, there’s probably many a time when they have to don their thinking cap. Well, now they can, literally."
  },
  Unicorn: {
    title: "this Unicorn Pencil Sharpener",
    imageUrl: require("./../../assets/images/Presents/00-Unicorn.jpg"),
    presentDescription:
      "What better way to ensure that their creativity is never blunted than to give them this magical way to keep their drawing tool fully sharpened."
  },
  Umbrella: {
    title: "this Umbrella Hat",
    imageUrl: require("./../../assets/images/Presents/00-Umbrella.jpg"),
    presentDescription:
      "Being an organised kind of person, they’ll appreciate this fun and practical way to stay dry!"
  },
  DishGloves: {
    title: "these Hand-Puppet Dish Gloves",
    imageUrl: require("./../../assets/images/Presents/00-DishGloves.jpg"),
    presentDescription:
      "They will have fun while cleaning up, and it might be enough to get them back on the nice list."
  },
  Clips: {
    title: "these Task Clips",
    imageUrl: require("./../../assets/images/Presents/00-Clips.jpg"),
    presentDescription:
      "These fun clips will help this person organise all their naughty thoughts."
  },
  Catapult: {
    title: "this Desktop Crossfire Catapult",
    imageUrl: require("./../../assets/images/Presents/00-Catapult.jpg"),
    presentDescription:
      "They will be able to keep their assortment of pens, paper clips and well-sharpened pencils tidy in a way that’s fun!"
  },
  Cactus: {
    title: "this Novelty Desk Tidy",
    imageUrl: require("./../../assets/images/Presents/00-Cactus.jpg"),
    presentDescription:
      "Tidy desk, tidy mind! Not only will it help the gift recipient tidy their stationery, but it will also add some fun to their desk set up."
  },
  Vacuum: {
    title: "the World's Smallest Vacuum",
    imageUrl: require("./../../assets/images/Presents/00-Vacuum.jpg"),
    presentDescription:
      "This gift does suck… but that’s a good thing, and it will help them keep their desk clean as well as orderly."
  }
};

export default presentData;
