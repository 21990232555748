import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";

import ChatWrapper from "./ChatWrapper";

import Isanta from "../Isanta/Isanta";
import IsantaShadow from "../IsantaShadow/IsantaShadow";
import chatbotStyles from "./chatbotStyles";
import chatbotSteps from "./chatbotSteps";

class Chat extends Component {
  constructor(props) {
    super(props);

    // Bind handleEnd to this component
    this.handleEnd = this.handleEnd.bind(this);
  }

  handleEnd({ steps, values }) {
    // Make the chatbot values easier to work with
    const chatData = {
      giver: values[1],
      receiver: values[2],
      ethics: values[3],
      humor: values[4],
      type: values[5]
    };

    // Add chatData to app state
    this.props.givePresents(chatData);
  }

  render() {
    return (
      <ChatWrapper>
        <Isanta />
        <IsantaShadow />
        <ThemeProvider theme={chatbotStyles}>
          <ChatBot
            className={"Chat__container"}
            hideHeader={true}
            steps={chatbotSteps}
            hideBotAvatar={true}
            hideUserAvatar={true}
            userDelay={500}
            height={"100%"}
            width={"100%"}
            handleEnd={this.handleEnd}
          />
        </ThemeProvider>
      </ChatWrapper>
    );
  }
}

export default Chat;
