import React, { Component } from "react";

import "./IsantaShadow.scss";

class IsantaShadow extends Component {
  render() {
    return <div className="IsantaShadow" />;
  }
}

export default IsantaShadow;
