import styled from "styled-components";
import breakpoints from "./../../globalStyles/breakpoints";
import mobileBush from "./../../assets/images/Mobile/Bush-L.svg";
import tabletBush from "./../../assets/images/Tablet/Bush-L.svg";
import desktopBush from "./../../assets/images/Desktop/Bush-L.svg";

const BushLeft = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0px;

  width: 219px;
  height: 119px;

  background: url(${mobileBush});

  @media (min-width: ${breakpoints.screenSm}) {
    background: url(${tabletBush});

    width: 423px;
    height: 194px;
  }

  @media (min-width: ${breakpoints.screenLg}) {
    background: url(${desktopBush});

    width: 530px;
    height: 274px;
  }
`;

export default BushLeft;
