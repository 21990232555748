import React, { Component } from "react";

import "../../App.scss";

class Logo extends Component {
  render() {
    return (
      <a className="Logo" href="https://thisplace.com">
        <p>
          Made with
          <span
            className="christmas-tree"
            role="img"
            aria-label="christmas tree"
          >
            🎄
          </span>
          at
        </p>
        <span className="tp-logo" />
      </a>
    );
  }
}

export default Logo;
