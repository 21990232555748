import React, { Component } from 'react';

import '../../App.scss';

class Moon extends Component {

  render() {
    return (
      <div className="Moon">
      </div>
    )
  }
}

export default Moon;
