import React, { Component } from "react";

import "./Isanta.scss";

class Isanta extends Component {
  render() {
    return <div className="Isanta" />;
  }
}

export default Isanta;
