import styled from "styled-components";
import Particles from "react-particles-js";

const Snowflakes = styled(Particles)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export default Snowflakes;
