import React, { Component } from 'react';

import '../../App.scss';

class BushRight extends Component {

  render() {
    return (
      <div className="BushRight">
      </div>
    )
  }
}

export default BushRight;
