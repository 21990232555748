import styled from "styled-components";
import breakpoints from "./../../globalStyles/breakpoints";

const PrivacyLink = styled.a`
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  right: 50%;
  transform: translateX(50%);
  background-color: rgba(24, 0, 42, 0.9);
  padding: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media (min-width: ${breakpoints.screenSm}) {
    right: 15px;
    transform: translateX(0);
  }
`;

export default PrivacyLink;
